import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Error404 from '../views/Error404.vue'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/search',
    name: 'Search',
    props: true,
    component: () => import('../views/Search.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  { path: '/:pathMatch(.*)*', name: 'not-found', component: Error404 },
  { path: '/:pathMatch(.*)', name: 'bad-not-found', component: Error404 },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  let userAuthorized = !!router.$store.state.user.token
  
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if(userAuthorized){
      next()
    }else{
      next({ path: '/login' })
    }
  }else{
    if(userAuthorized && to.name === "Login"){
      next({ path: '/' })
    }else{
      next()
    }
  }
})

export default router
