import _map from 'lodash/map'
import _sortBy from 'lodash/sortBy'

const state = {
  vaults: [],
};

const getters = {
  structure(state, getters, rootState, rootGetters) {
    return _sortBy(
      _map(state.vaults, vault => { return { 
        type: "vault", 
        ...vault,
        data: rootGetters['magnets/structureByVault'](vault.id),
      }}),
      vault => vault.name
    )
  }
};

const actions = {
  get(context){
    this.$api.request('/vaults', 'get').then(result => {
      context.commit("SAVE_VAULTS", result.data)
    }).catch(() => {
      //
    })
  },

  clearStorage(context){
    context.commit("SAVE_VAULTS", [])
  },
};

const mutations = {
  SAVE_VAULTS(state, vaults) {
    state.vaults = vaults
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
