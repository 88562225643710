import _map from 'lodash/map'
import _sortBy from 'lodash/sortBy'
import _filter from 'lodash/filter'
import updateModuleState from '../functions/updateModuleState';

const state = {
  documents: [],
};

const getters = {
  structureByMagnet: (state) => (magnetId) => {
    let magnetDocuments = _filter(state.documents, document => document.magnetId === magnetId)

    return _sortBy(
      _map(magnetDocuments, document => { return { 
        type: "file", 
        ...document,
        }
      }),
      document => document.name
    )
  },
};

const actions = {
  getFromMagnet(context, magnetId){
    return new Promise((resolve) => {
      this.$api.request(`/magnets/${magnetId}/documents`, 'get', {
        "count": 500
      })
        .then(result => {
          context.commit("SAVE_DOCUMENTS", {
            magnetId,
            data: result.data,
          })
          resolve()
        }).catch(() => {
          resolve()
        })
    })
  },

  clearStorage(context){
    context.commit("CLEAR_DOCUMENTS")
  },
};

const mutations = {
  SAVE_DOCUMENTS(state, payload) {
    //add vaultId to object
      let magnetDocuments = _map(payload.data, magnet => { return {
        magnetId: payload.magnetId,
        ...magnet,
      }})
      
      state.documents = updateModuleState(state.documents, magnetDocuments)
  },

  CLEAR_DOCUMENTS(state){
    state.documents = []
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
