import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

//MODULES
import user from './modules/user';
import vaults from './modules/vaults';
import magnets from './modules/magnets';
import documents from './modules/documents';


export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    vaults,
    magnets,
    documents,
  },
  plugins: [createPersistedState()],
})
