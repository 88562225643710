<template>
  <div class="flex items-center h-screen">
    <div class="w-full  md:w-1/2 lg:w-1/3 m-auto inline-flex flex-col items-center bg-primary shadow p-4 rounded">
      <div class="mb-10 w-full">
        <img
          class="block object-contain w-full max-h-16"
          src="@/assets/logo.svg"
          alt="bwtarif"
        >
      </div>
      <form class="w-full" @submit.prevent="login">
        <div>
          <v-input
            v-model="username"
            id="email" 
            name="email" 
            type="text"
            icon="envelope" 
            placeholder="beispiel@email.com" 
          />
        </div>

        <div class="mt-4">
          <v-input
            v-model="password" 
            id="password" 
            name="password" 
            type="password" 
            icon="lock" 
            placeholder="*******" 
          />
        </div>
        <div v-if="loginError" class="mt-5 text-xs text-red-500">Benutzername oder Password falsch</div>

        <div class="flex justify-end">
          <v-button type="submit" class="mt-10">Anmelden</v-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import VInput from '@/components/v-input.vue';
import VButton from '@/components/v-button.vue';

export default {
  name: 'Login',

  components: {
    VInput,
    VButton
  },

  data() {
    return {
      username: "",
      password: "",
      loginError: false,
    }
  },

  computed: {
    credentials(){
      return this.$store.state.user.credetials
    }
  },

  methods: {
    login(){  

      this.loginError = false
      this.$store.dispatch('user/login', {
        "username": this.username,
        "password": this.password,
      }).then(result => {
        if(result){
          this.$router.push({ name: 'Home' })
        }else{
          this.loginError = true
        }
      })
    },
  },
}
</script>
