import _groupBy from 'lodash/groupBy';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _includes from 'lodash/includes';

export default function(stateData, newData){

    //no changes return previous state
    if(!newData.length) return stateData

    //copy current state
    let tempState = [...stateData];

    //map all existing ids
    let stateIds = _map(tempState, obj => obj.id);

    //check what data needs to be added or updated
    let groupedData = _groupBy(newData, obj => _includes(stateIds, obj.id));
    let insertData = groupedData.false ? groupedData.false : [];
    let updateData = groupedData.true ? groupedData.true : [];

    //insert
    if(insertData.length){
        tempState.push(...insertData);
    }

    //update data
    if(updateData.length){
        tempState = _map(tempState, obj => _find(updateData, o => o.id === obj.id) || obj)
    }

    //delete deleted data
    //tempState = _filter(tempState, obj => !obj.deleted)

    return tempState;
}
