<template lang="">
  <div class="flex border border-gray-300 shadow rounded">
    <div class="max-h-full overflow-auto transition-width duration-300 ease-in-out" :class="{ 'w-0 lg:w-1/2' : detailsVisible, 'w-full lg:w-full' : !detailsVisible }">
        <slot />
    </div>
    <div
      v-show="detailsVisible"
      class="flex flex-col duration-300 ease-in-out" 
      :class="{ 'w-full lg:w-1/2' : detailsVisible }" 
    >
      <div class="flex items-center bg-primary text-primary p-2 lg:p-3 border-l border-gray-50">
        <h3 class="font-normal lg:font-bold flex-grow break-all text-gray-50">{{ selectedFile ? selectedFile.name : '' }}</h3>
        <v-button @click="showModalFullScreenImage(true)">
          <font-awesome-icon
            icon="expand"
            title="Vollbildanzeige"
            class="text-base lg:text-xl text-blue-500"
          />
        </v-button>
        <v-button class="ml-2 lg:ml-4" @click="clickedDownloadButton()">
          <font-awesome-icon
            icon="download"
            title="Datei herunterladen"
            class="text-base lg:text-xl text-blue-500"
          />
        </v-button>
        <v-button class="ml-2 lg:ml-4" @click="hideDetails()">
          <font-awesome-icon
            icon="times"
            title="Datei schließen"
            class="text-base lg:text-xl text-red-500"
          />
        </v-button>
      </div>
      <div class="relative flex items-center justify-center flex-grow">
        <button @click="showModalFullScreenImage(true)">
          <img 
            v-if="selectedFilePreview.index in selectedFilePreview.urls"
            class="absolute top-0 left-0 w-full h-full object-contain"
            :src="selectedFilePreview.urls[selectedFilePreview.index]"
            alt="Workflow"
          />
        </button>

        <template v-if="selectedFile && selectedFile.pageCount > 1">
          <v-button v-if="selectedFilePreview.index != 0" class="absolute left-1" @click="previousPreviewPage()">
              <font-awesome-icon
                icon="arrow-left"
                class="text-lg"
            />
          </v-button>

          <v-button v-if="selectedFilePreview.index + 1 != selectedFile.pageCount" class="absolute right-1" @click="nextPreviewPage()">
              <font-awesome-icon
                icon="arrow-right"
                class="text-lg"
            />
          </v-button>
          <span class="absolute bottom-0 bg-primary text-gray-50 rounded-t italic text-xs px-2 py-1">
            Seite {{ selectedFilePreview.index + 1 }}/{{ selectedFile.pageCount }}
          </span>
        </template>
        
        <div v-if="selectedFilePreview.queue.includes(selectedFilePreview.index)" class="flex h-full justify-center items-center">
          <font-awesome-icon
            icon="spinner"
            class="text-5xl text-primary animate-spin"
          />
        </div>
      </div>
    </div>

    <div v-if="showFullScreenImage" class="absolute top-0 left-0 w-full h-full p-6">
      <div class="absolute top-0 left-0 w-full h-full bg-primary opacity-80"></div>
      <div class="h-full w-full pt-16">
        <div class="relative flex items-center justify-center w-full h-full">
          <button class="absolute flex items-center justify-center right-0 top-0 p-2 w-10 h-10 z-40 bg-primary hover:bg-accent rounded-full" @click="showModalFullScreenImage(false)">
            <font-awesome-icon
              icon="times"
              class="text-xl lg:text-3xl text-red-500"
            />
          </button>
          <img
            v-if="selectedFilePreview.index in selectedFilePreview.urls"
            class="absolute top-0 left-0 w-full h-full object-contain opacity-100"
            :src="selectedFilePreview.urls[selectedFilePreview.index]"
            alt="Workflow"
          />
          <template v-if="selectedFile && selectedFile.pageCount > 1">
            <v-button v-if="selectedFilePreview.index != 0" class="absolute left-1" @click="previousPreviewPage()">
                <font-awesome-icon
                  icon="arrow-left"
                  class="text-lg lg:text-3xl"
              />
            </v-button>

            <v-button v-if="selectedFilePreview.index + 1 != selectedFile.pageCount" class="absolute right-1" @click="nextPreviewPage()">
                <font-awesome-icon
                  icon="arrow-right"
                  class="text-lg lg:text-3xl"
              />
            </v-button>
            <span class="absolute bottom-0 bg-primary text-gray-50 rounded-t italic text-xs px-2 py-1">
              Seite {{ selectedFilePreview.index + 1 }}/{{ selectedFile.pageCount }}
            </span>
          </template>
          <div v-if="selectedFilePreview.queue.includes(selectedFilePreview.index)" class="flex h-full justify-center items-center">
            <font-awesome-icon
              icon="spinner"
              class="text-5xl text-gray-300 animate-spin"
            />
          </div>
        </div>
      </div>
    </div>


    <div v-if="showDownloadSelectionModal" class="absolute flex justify-center items-center top-0 left-0 z-50 w-screen h-screen bg-primary bg-opacity-50">
      <div class="text-center p-5 bg-gray-300 rounded">
        <p class="text-xl">Welche Datei wollen Sie herunterladen?</p>

        <div class="flex mt-8">
          <v-button class="text-sm w-28 text-center" @click="showDownloadSelectionModal = false">
            <span class="w-full text-center">Abbrechen</span>
          </v-button>
          <v-button class="text-sm w-28 ml-6 text-center" @click="downloadSelectedFile(false)">
            <span class="w-full text-center">Original</span>
          </v-button>
          <v-button class="text-sm w-28 ml-6 text-center" @click="downloadSelectedFile(true)">
            <span class="w-full text-center">mit Notizen</span>
          </v-button>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import Emitter from 'tiny-emitter'
import VButton from '@/components/v-button.vue'
import _filter from 'lodash/filter'

export default {
  name: 'FileManager',

  components: {
    VButton,
  },

  data() {
    return {
      selectedFile: null,
      detailsHidden: false,
      selectedFilePreview: {
        urls: [],
        index: 0,
        queue: [],
        queueRunning: false,
      },
      showFullScreenImage: false,
      showDownloadSelectionModal: false,
    }
  },

  created() {
    window.fileManagerEmitter = new Emitter()
    window.fileManagerEmitter.on('fileSelected', this.showDetails)
  },

  beforeUnmount() {
    window.fileManagerEmitter = null
  },

  computed: {
    detailsVisible(){
      return this.selectedFile && !this.detailsHidden
    },
  },

  watch: {
    'selectedFilePreview.queue': {
      handler: function (val) {
        if(!this.selectedFilePreview.queueRunning && val.length){
          this.startDownloadingFilePreviews()
        }   
      },
      deep: true
    }
  },

  methods: {
    showModalFullScreenImage(value){
      this.showFullScreenImage = value

      if(value){  
        window.addEventListener('keydown', this.escapeCloseModal, {once: true});
      }
    },

    escapeCloseModal(e){
      if (e.key == "Esc" || e.key == "Escape") {
          this.showFullScreenImage = false
      }
    },

    async startDownloadingFilePreviews(){
      this.selectedFilePreview.queueRunning = true

      let urlCreator = window.URL || window.webkitURL
      while (this.selectedFilePreview.queue.length) {
        let index = this.selectedFilePreview.queue[0];
        let response = await this.$api.request(`/documents/${this.selectedFile.id}/preview?size=LARGE&page=${index + 1}`, 'get', null, {
          'Accept': '*/*',
        }, {
          responseType: 'blob',
        })

        let data = await response.data  
        this.selectedFilePreview.queue = _filter(this.selectedFilePreview.queue, item => item !== index)
        this.selectedFilePreview.urls.splice(index, 0, urlCreator.createObjectURL(data))
      }
      this.selectedFilePreview.queueRunning = false
    },

    addToPreviewQueue(){
      let loadingItems = 3
      let missingIndexes = this.selectedFile.pageCount - this.selectedFilePreview.index
      let preloadIndex = missingIndexes >= loadingItems ? this.selectedFilePreview.index + loadingItems : this.selectedFilePreview.index + missingIndexes
      
      for (let index = this.selectedFilePreview.index; index < preloadIndex;) {
        if(!(index in this.selectedFilePreview.urls) && !this.selectedFilePreview.queue.includes(index)){
          let temp = this.selectedFilePreview.queue
          temp.push(index)
          this.selectedFilePreview.queue = temp
        }
        index++;
      }
    },

    showDetails(file = null){
  
      let urlCreator = window.URL || window.webkitURL
      this.selectedFilePreview.urls.forEach(url => {
        urlCreator.revokeObjectURL(url)  
      });
      this.selectedFilePreview.urls = []
      this.selectedFilePreview.index = 0

      if(file){
        this.detailsHidden = false
        this.selectedFile = file
        this.addToPreviewQueue()
      }
    },

    previousPreviewPage(){
      if(this.selectedFilePreview.index == 0){
        return
      }

      this.selectedFilePreview.index--
      this.addToPreviewQueue()
    },

    nextPreviewPage(){
      if(this.selectedFilePreview.index + 1 == this.selectedFile.pageCount){
        return
      }
      
      this.selectedFilePreview.index++
      this.addToPreviewQueue()
    },
    
    hideDetails(){
      window.fileManagerEmitter.emit('fileSelected', null)
      this.detailsHidden = true
    },

    clickedDownloadButton(){
      if(this.selectedFile.annotationsVersion > 0){
        this.showDownloadSelectionModal = true
      }else{
        this.downloadSelectedFile()
      }
    },

    downloadSelectedFile(withAnootation = false){
        
        this.showDownloadSelectionModal = false
        
        let url = `/documents/${this.selectedFile.id}/file`
        if(withAnootation){
          url = `/documents/${this.selectedFile.id}/annotated-pdf`
        }

        this.$api.request(url, 'get', null, {
          'Accept': '*/*',
        }, {
          responseType: 'blob',
        }).then((response) => {
          let urlCreator = window.URL || window.webkitURL
          const url = urlCreator.createObjectURL(response.data)
          const a = document.createElement("a")
          a.href = url
          a.download = this.selectedFile.name
          a.click()
          urlCreator.revokeObjectURL(url)
        })
    },
  },
}
</script>
<style>
/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  @apply bg-gray-200;
}

/* Handle */
::-webkit-scrollbar-thumb {
    @apply bg-accent
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover{
    @apply bg-accent
}
</style>