<template>
  <div class="flex flex-col h-full max-w-7xl mx-auto">
    <h1>Übersicht der Dateien</h1>

    <file-manager class="flex-grow min-h-0">
      <file-tree :structure="fileStructure"></file-tree>
    </file-manager>

    <div v-if="zipExport.active" class="absolute flex justify-center items-center top-0 left-0 z-50 w-screen h-screen bg-primary bg-opacity-50">
      <div class="text-center p-5 bg-gray-300 rounded">
        <p class="text-xl">Export der Dateien</p>
        

        <div class="mt-6 mb-3">
          <font-awesome-icon
            v-if="zipExport.state != ZipExportState.downloading"
            icon="spinner"
            class="animate-spin text-3xl"
          />  

          <progress-bar v-if="zipExport.state == ZipExportState.downloading" :percentage="percentFilesDownloaded"></progress-bar>
        </div>

        <p class="text-xs">{{ zipExportStateText }}</p>

        <p class="mt-5 text-xs text-gray-600 italic">Dies kann je nach Ordnergröße einige Minuten dauern.</p>

        <v-button class="text-sm mt-5" @click="stopExport()">
          <font-awesome-icon
            icon="times-circle"
          />
          <span class="ml-3">Abbrechen</span>
        </v-button>
      </div>
    </div>
  </div>
</template>

<script>
import Emitter from 'tiny-emitter'
import FileManager from '@/components/fileManager/fileManager.vue'
import FileTree from '@/components/fileManager/fileTree.vue'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import ProgressBar from '@/components/progressBar.vue'
import VButton from '@/components/v-button.vue'


export default {
  name: 'Home',

  components: {
    FileManager,
    FileTree,
    ProgressBar,
    VButton,
  },

  data() {
    return {
      fileStructure: this.$store.getters['vaults/structure'],
      ZipExportState: {
        preparing: 1,
        downloading: 2,
        zipDownload: 3,
      },
      zipExport: {
        active: false,
        state: null,
        filesToDownload: 0,
        filesDownloaded: 0,
      }
    }
  },

  created() {
    this.$store.dispatch('vaults/get')

    window.zipExportEmitter = new Emitter()
    window.zipExportEmitter.on('startCSVExport', this.exportFolder)
  },

  watch: {
    computedFileStructure(val){
      if(!this.zipExport.active){
        this.fileStructure = val
      }
    },

    percentFilesDownloaded(val){
      if(val == 100){
        this.zipExport.state = this.ZipExportState.zipDownload
      }
    }
  },

  computed: {
    computedFileStructure(){
      return this.$store.getters['vaults/structure']
    },

    percentFilesDownloaded(){
      return (this.zipExport.filesDownloaded/this.zipExport.filesToDownload)*100
    },

    zipExportStateText(){
      switch (this.zipExport.state) {
        case this.ZipExportState.preparing:
          return "Dateien und Ordner werden für den Export gesammelt"
        case this.ZipExportState.downloading:
          return "Dateien werden heruntergeladen"
        default:
          return "Archiv wird vorbereitet und Ihnen gleich zur Verfügung stehen"
      }
    },
  },

  methods: {

    stopExport(){
      this.zipExport.active = false
    },

    async exportFolder(folder){
      
      this.zipExport.state = this.ZipExportState.preparing
      this.zipExport.active = true
      this.zipExport.filesToDownload = 0
      this.zipExport.filesDownloaded = 0

      await this.loadingFolderContent(folder)

      if (!this.zipExport.active){
        return
      }

      var structure = null
      if(folder.type === "vault"){
        structure = this.$store.getters["magnets/structureByVault"](folder.id)
      }else{
        structure = this.$store.getters["magnets/contentOfFolder"](folder.id)
      }

      if (!this.zipExport.active){
        return
      }
      
      this.countDocumentsInFolder(structure[0])
      this.zipExport.state = this.ZipExportState.downloading

      var zip = new JSZip();
      var rootFolder = zip.folder(folder.name)
      this.addStructureToExportZip(structure, rootFolder)
      
      zip.generateAsync({type:"blob"})
      .then((content) => {
        if (this.zipExport.active){
          this.zipExport.active = false
          saveAs(content, folder.name);
        } 
      });
    },

    async loadingFolderContent(folder){

      //to cancel the export
      if (!this.zipExport.active){
        return
      }

      var structure = null
      if(folder.type === "magnet"){
        await this.$store.dispatch('documents/getFromMagnet', folder.id)
        structure = folder.data
      }else{
        await this.$store.dispatch('magnets/getFromVault', folder.id)
        structure = this.$store.getters["magnets/structureByVault"](folder.id)
      }
      
      for (const element of structure) {

        //to cancel the export
        if (!this.zipExport.active){
          break;
        }

        if(element.type === "magnet"){
          await this.loadingFolderContent(element)
        }
      }
    },

    countDocumentsInFolder(folder){
      folder.data.forEach(item => {
        if(item.type === "magnet"){
          this.countDocumentsInFolder(item)
        }else{
          this.zipExport.filesToDownload = this.zipExport.filesToDownload + 1
        }
      });
    },

    addStructureToExportZip(folder, zipReference){
      var zipFolder = zipReference
      var data = folder

      if(!Array.isArray(folder)){
        data = folder.data
        zipFolder = zipReference.folder(folder.name)
      }
      

      for (const item of data) {
        if(item.type === "magnet"){
          this.addStructureToExportZip(item, zipFolder)
        }else{
          zipFolder.file(item.name, this.downloadFile(item.id))
        }
      }
    },

    async downloadFile(fileId){
      const response = await this.$api.request(`/documents/${fileId}/file`, 'get', null, {
        'Accept': '*/*',
      }, {
        responseType: 'blob',
      })
      this.zipExport.filesDownloaded = this.zipExport.filesDownloaded + 1
      return await response.data;
    },
  },
}
</script>