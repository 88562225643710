<template>
  <div class="relative">
    <div v-if="icon" class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
      <font-awesome-icon
        :icon="icon"
        class="text-gray-500"
      />
    </div>
    <input
      @input="$emit('update:modelValue', $event.target.value)"
      @change="$emit('update:modelValue', $event.target.value)"
      :value="modelValue"
      v-bind="$attrs"
      class="w-full bg-white border border-gray-300 rounded-md py-2 pl-3 pr-3 text-sm placeholder-gray-400 focus:outline-none focus:text-primary focus:placeholder-gray-400 focus:ring-1 focus:ring-accent focus:border-accent sm:text-sm"
      :class="{ 'pl-10' : !!icon }"
    />
  </div>
</template>

<script>
export default {
  name:"VInput",

  inheritAttrs: false,
  
  emits: ['update:modelValue'],
  
  props: {
    modelValue: String,
    icon: { type: String, required: false, default: null },
  },
}
</script>