import _map from 'lodash/map'
import _filter from 'lodash/filter'
import _sortBy from 'lodash/sortBy'
import updateModuleState from '../functions/updateModuleState';

const state = {
  magnets: [],
};

const getters = {
  structureByVault: (state, getters, rootState, rootGetters) => (vaultId) => {
    let vaultMagnets = _filter(state.magnets, magnet => magnet.vaultId === vaultId && magnet.parentMagnetId === null)
    
    return _sortBy(
      _map(vaultMagnets, magnet => { return { 
        type: "magnet", 
        ...magnet,
        data: _sortBy(
          getters['structureByMagnet'](magnet.id).concat(rootGetters['documents/structureByMagnet'](magnet.id)),
          item => item.name
        )
      }}),
      magnet => magnet.name
    )
  },

  structureByMagnet: (state, getters, rootState, rootGetters) => (parentMagnetId) => {

    let magnets = _filter(state.magnets, magnet => magnet.parentMagnetId === parentMagnetId)
    
    return _sortBy(
      _map(magnets, magnet => { return { 
        type: "magnet", 
        ...magnet,
        data: _sortBy(
          getters['structureByMagnet'](magnet.id).concat(rootGetters['documents/structureByMagnet'](magnet.id)),
          item => item.name
        )
      }}),
      magnet => magnet.name
    )
  },

  contentOfFolder: (state, getters, rootState, rootGetters) => (magnetId) => {
    let magnet = _filter(state.magnets, magnet => magnet.id === magnetId)

    return [{ 
      type: "magnet", 
      ...magnet,
      data: _sortBy(
        getters['structureByMagnet'](magnetId).concat(rootGetters['documents/structureByMagnet'](magnetId)),
        item => item.name
      )
    }]
  },
};

const actions = {
  getFromVault(context, vaultId){
    return new Promise((resolve) => {
      this.$api.request(`/vaults/${vaultId}/magnets`, 'get')
        .then(result => {
          context.commit("SAVE_MAGNETS", {
            vaultId: vaultId,
            test: vaultId,
            data: result.data,
          })
          resolve()
        }).catch(() => {
          resolve()
        })
    })
  },
  
  clearStorage(context){
    context.commit("CLEAR_MAGNETS")
  },
};

const mutations = {
  SAVE_MAGNETS(state, payload) {
    //add vaultId to object
      let vaultMagnets = _map(payload.data, magnet => { return {
        vaultId: payload.vaultId,
        ...magnet,
      }})

      state.magnets = updateModuleState(state.magnets, vaultMagnets)
  },
  
  CLEAR_MAGNETS(state){
    state.magnets = []
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
