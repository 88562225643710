const state = {
    token: null,
    credetials: null,
};

const getters = {};

const actions = {
    login(context, { username, password }){
        return new Promise((resolve) => {
            this.$api.request('/token', 'post', {
                userName: username,
                password,
            }).then(response => {
                context.commit("SAVE_CREDENTIALS", {
                    username,
                    password,
                })
                context.commit("SAVE_TOKEN", response.data)
                this.$api.token = response.data
                resolve(true)
            }).catch(() => {
                resolve(false)
            })
        })
    },

    logout(context){
        context.commit("SAVE_TOKEN", null)
        context.commit("SAVE_CREDENTIALS", null)
        context.dispatch('vaults/clearStorage', null, { root:true })
        context.dispatch('magnets/clearStorage', null, { root:true })
        context.dispatch('documents/clearStorage', null, { root:true })
        this.$router.push({ name: 'Login' })
    },
};

const mutations = {
    SAVE_CREDENTIALS(state, credentials) {
        state.credetials = credentials
    },

    SAVE_TOKEN(state, token){
        state.token = token
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
