<template lang="">
  <div
    :class="{ 'flex bg-primary' : isOpen, 'border-b border-gray-200' : !isOpen }"
  >
    <div
      class="w-0 transition-width duration-300 ease-in-out bg-primary"
      :class="{ 'w-3' : isOpen }"
    />
    <div class="flex-grow" style="overflow-wrap: anywhere;">
      <div
        @click="changeOpenState(!isOpen)"
        class="flex items-center p-2 cursor-pointer"
        :class="{ 'text-gray-50' : isOpen }"
      >
        <font-awesome-icon
          :icon="icon"
          class="mr-2"
        />
        <span
          class="flex-grow"
        >{{ folder.name }}</span>

        
        <font-awesome-icon
          v-if="loading"
          icon="spinner"
          class="animate-spin mr-2"
        />  

        <button class="mr-2 hover:text-accent focus:outline-none p-1 rounded focus:ring-2 focus:ring-accent" @click.stop="exportFolder()">
          <font-awesome-icon
            icon="file-export"
          />
        </button>
  
        <font-awesome-icon
          v-show="isOpen"
          icon="arrow-circle-up"
        />
        
        <font-awesome-icon
          v-show="!isOpen"
          icon="arrow-circle-down"
        />
      </div>
      <div
        class="max-h-0 bg-gray-50 transition-height duration-300 ease-in-out overflow-hidden"
        :class="{ 'max-h-unlimited' : isOpen }"
      >
        <slot :identifier="identifier" :loading="loading" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FileTreeAccordion",

  props: {
    folder: { type: Object, required: true },
    parentIdentifier: { type: String, required: false , default: null },
  },

  data() {
      return {
      isOpen: false,
      loading: false,
      identifier: Math.random().toString(36).substring(7),
      loadingExport: false,
      exportFiles: 0,
    }
  },
  
  created() {
    window.fileTreeEmitter.on(`close-accordion-${this.parentIdentifier}`, this.changeOpenState)

    /*window.fileTreeEmitter.on(`close-accordions-in-${this.parentIdentifier}`, (val) => {
        if(this.identifier !== val.exceptIdentifier){
          this.changeOpenState(false)
        }
      })*/
  },

  computed: {
    icon(){
      if(this.folder.type === "vault"){
        return 'database'
      }
      return 'magnet';
    },

    openStateIcon(){
      return this.open ? 'arrow-circle-up' : 'arrow-circle-down'
    },
  },

  methods: {
    changeOpenState(val){
      this.isOpen = val

      if(this.isOpen){
        window.fileTreeEmitter.emit(`close-accordions-in-${this.parentIdentifier}`, {
          exceptIdentifier: this.identifier
        })
      }else{
        window.fileTreeEmitter.emit(`close-accordion-${this.identifier}`, false)
      }

      //load folder data
      if(this.isOpen){
        this.loadFolderFiles()
      }
    },

    loadFolderFiles(){
      if(this.folder.type === "vault"){
        this.loading = true
        window.fileTreeEmitter.emit(`loading-files-of-folder-${this.identifier}`, true)
        this.$store.dispatch('magnets/getFromVault', this.folder.id).then(() => {
          this.loading = false
          window.fileTreeEmitter.emit(`loading-files-of-folder-${this.identifier}`, false)
        })
      }else if(this.folder.type === "magnet"){
        this.loading = true
        window.fileTreeEmitter.emit(`loading-files-of-folder-${this.identifier}`, true)
        this.$store.dispatch('documents/getFromMagnet', this.folder.id).then(() => {
          window.fileTreeEmitter.emit(`loading-files-of-folder-${this.identifier}`, false)
          this.loading = false
        })
      }
    },

    exportFolder(){
      window.zipExportEmitter.emit('startCSVExport', this.folder)
    },
  },
}
</script>