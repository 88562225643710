<template lang="">
  <header class="fixed top-0 z-40 w-full grid grid-cols-12 gap-4 py-4 px-4 bg-primary">
    <div class="col-span-2 flex justify-start items-center">
      <router-link to="/" class="w-full flex justify-start">
        <img
          class="block object-contain max-h-10"
          src="@/assets/logo.svg"
          alt="bwtarif"
        >
      </router-link>
    </div>
    
    <form @submit.prevent="startSearch()" class="col-span-8 flex justify-center">
      <label
        for="search"
        class="sr-only"
      >Search</label>
      <div class="w-full xl:w-2/3">
        <v-input
          v-model="$root.search"
          id="search"
          icon="search"
          name="search"
          placeholder="Suche"
          type="search"
        />
      </div>
    </form>
    <div class="col-span-2 flex justify-end">
      <v-button @click="logout()">
        <span class="hidden md:inline-block">Logout</span>
        <span class="ml-2">
          <font-awesome-icon
            icon="sign-out-alt"
            class="text-lg"
          />
        </span>
      </v-button>
    </div>
  </header>
</template>
<script>
import VButton from '@/components/v-button.vue'
import VInput from '@/components/v-input.vue'

export default {
    name: "Navigation",

    components: {
      VButton,
      VInput,
    },

    data() {
      return {
        search: "",
      }
    },

    methods: {
      startSearch(){
        this.$router.push({ name: 'Search', params: { search: this.search } })
      },

      logout(){
        this.$store.dispatch('user/logout')
      }
    },
}
</script>
<style lang="">
    
</style>