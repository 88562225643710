<template>
  <div class="h-screen">
    <navigation v-if="userLoggedIn" />
    <main class="h-full mx-4 xl:mx-8" :class="{ 'pt-28 pb-4' : userLoggedIn }">
      <router-view  />
    </main>
  </div>
</template>

<script>
import navigation from './components/navigation.vue';


export default {
  components: { navigation },
  
  data() {
    return {
      search: "",
    }
  },

  computed: {
    userLoggedIn(){
      return !!this.$store.state.user.token
    }
  }
}
</script>