<template lang="">
  <div @click="showDetails()" class="cursor-pointer" :class="{ 'bg-accent' : selected }">
    <slot />
  </div>
</template>
<script>
export default {
  name: "FileContainer",

  props: {
    file: { type: Object, required: true}
  },

  data() {
    return {
      selected: false,
    }
  },

  created() {
    window.fileManagerEmitter.on('fileSelected', this.checkIfSelfSelected)
  },

  methods: {
      showDetails(){
        window.fileManagerEmitter.emit('fileSelected', this.file)
      },

      checkIfSelfSelected(file){
        this.selected = file === this.file
      }
  },
}
</script>
<style lang="">
    
</style>