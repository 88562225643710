<template lang="">
  <div class="text-primary">
    <div v-if="loading  && !hasStructureItems">        
      <div class="flex w-full justify-center py-1">
        <font-awesome-icon
        icon="spinner"
        class="animate-spin text-primary"
      />  
      </div>
    </div>
    <template v-for="item in structure">
      <template v-if="isStrcutureItemFolder(item.type)">
        <file-tree-accordion
          :key="item.id"
          :folder="item"
          :parent-identifier="parentIdentifier"
        >
          <template v-slot:default="slotProps">
            <file-tree :structure="item.data" :parent-identifier="slotProps.identifier" />
          </template>
        </file-tree-accordion>
      </template>
      <template v-if="item.type == 'file'">
        <file-container :key="item.id" :file="item">
          <div
            class="flex items-center p-2 border-t text-primary"
          >
            <font-awesome-icon
              icon="file"
              class="mr-2"
            />
            <div class="flex-grow">{{ item.name }}</div>
          </div>
        </file-container>
      </template>
    </template>
    <div
      v-show="!loading && !hasStructureItems"
      class=" italic text-primary transition-height duration-300 ease-in-out max-h-unlimited p-2"
    >
      <font-awesome-icon
        icon="exclamation-circle"
        class="mr-2"
      />
      <span>Es liegen keine Dateien vor</span>
    </div>
  </div>
</template>
<script>
import Emitter from 'tiny-emitter'
import FileTreeAccordion from '@/components/fileManager/fileTreeAccordion.vue'
import FileContainer from '@/components/fileManager/fileContainer.vue'
import FileTree from '@/components/fileManager/fileTree.vue'

export default {
    name: "FileTree",

    props: {
        structure: { type: Array, default: null },
        folderType: { type: String, default: "vault" },
        folderId: { type: String, default: null },
        parentIdentifier: { type: String, default: null },
    },
    
    data() {
      return {
        loading: false,
      }
    },

    components: {
        FileTreeAccordion,
        FileContainer,
        FileTree,
    },
    
    created() {
      if(!this.parentIdentifier){
        window.fileTreeEmitter = new Emitter()
      }

      window.fileTreeEmitter.on(`loading-files-of-folder-${this.parentIdentifier}`, (val) => {
        this.loading = val
      })
    },

    computed: {
      hasStructureItems(){
        return this.structure && this.structure.length
      }
    },
    
    beforeUnmount() {
      window.fileTreeEmitter = null
    },

    methods: {
      getStrcutureItemIcon(itemType){
        switch (itemType) {
          case 'vault':
            return 'database'
          default:
            return 'magnet';
        }
      },

      isStrcutureItemFolder(itemType){
        return ['vault', 'magnet'].includes(itemType)
      },
    },
}
</script>