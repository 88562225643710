<template lang="">
  <div class="text-center mt-20">
    <p class="font-bold text-2xl text-primary">Entschuldigung die gewünschte Seite konnte nicht gefunden werden</p>
    <router-link to="/" >
      <v-button class="mt-8">zurück zur Startseite</v-button>
    </router-link>
  </div>
</template>
<script>

import VButton from '@/components/v-button.vue';
export default {
  components: {
    VButton,
  }
}
</script>
<style lang="">
  
</style>