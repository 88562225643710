import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/main.css';
import Api from './Api';

//font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(fas)

//create vue app
const app = createApp(App)
    .use(store)
    .use(router)
    .component('FontAwesomeIcon', FontAwesomeIcon)

//setting api and its references
const api = new Api()
api.$store = store

//add global references
app.config.globalProperties.$api = api

//add store references
store.$api = api
store.$router = router

//add router references
router.$store = store

//mount app
app.mount('#app')

