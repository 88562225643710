
import axios from 'axios';
import _merge from 'lodash/merge'
axios.defaults.baseURL = 'https://amagno.me/api/v2'

const HEADERS = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
}

class Api {
    request(url, method, params, headers, nConfig, requestRetried){
        
        //prepare axios config
        let config = {
            method,
            url,
            headers: _merge(HEADERS, headers),
        }

        if(method === "get"){
            config.params = params
        }else{
            config.data = JSON.stringify(params)
        }

        //set bearer token
        let token = this.$store.state.user.token
        if(token){
            config.headers.Authorization = `Bearer ${token}`
        }

        config = _merge(config, nConfig)

        //axios requests
        return new Promise((resolve, reject) => {
            axios.request(config)
                .then(response => { 
                    resolve(response)
                })
                .catch(error => {
                    switch (error.response.status) {
                        case 401:
                            if(url === "/token"){
                                if(requestRetried){
                                    this.$store.dispatch('user/logout') 
                                }else{
                                    reject(error.response)
                                }
                            }else{
                                this.$store.dispatch('user/login', this.$store.state.user.credetials)
                                .then(resault => {
                                    if(resault){
                                        resolve(this.request(url, method, params, headers, config, true))
                                    }else{
                                        this.$store.dispatch('user/logout')
                                    }
                                })
                            }
                            break;
                        default:
                            reject(error.response)
                            break;
                    }
                });
        })
        
    }
}

export default Api